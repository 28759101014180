.mainContainerStyle{
    max-width: 1440px;
    margin: auto;
}

.topNavSectionStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 64px 64px 24px 64px;
}
.logoTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-size: 16px;
    color: var(--textBoldColor);
    font-weight: 600;
}
/* mobile view style start */
.mobileViewContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 64px 24px;
    align-items: center;
    width: 100%;
    height: 100%;
}
.mobileHeaderTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 50px;
    color: var(--textBoldColor);
    text-align: center;
}
.mobileDescTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: var(--textLightColor);
    text-align: center;
}
.gamesContainerStyle{
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 24px 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
}
.gameCardContainerStyle{
display: flex;
flex-direction: column;
gap: 16px;
align-items: center;
width: 100%;
height: 100%;
}
.gameImgWrapperStyle{
    min-width: 264px;
    min-height: 238px;
    width: 100%;
    height: 100%;
}
.gameNameTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: var(--black);
    text-align: center;
}
.mobileBtnStyle{
    background-color: var(--white);
    color: var(--text2BoldBlackColor);
    width: fit-content;
    border: 1px solid var(--black);
}
.scanBtnStyle{
    display: none;
}
/* mobile view style end */
/* hero section styles start */

.heroMainContainerStyle{
    padding: 64px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 64px;
    justify-content: space-between;
    width: 100%;
}
.imageWrapperStyle{
    max-width: 572px;
    max-height: 488px;
    width: 100%;
    height: 100%;
}
.imageStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.heroHeaderDescTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: var(--textLightColor);
}

.heroSectionTextContainerStyle{
    max-width: 572px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 64px;
}
.heroDescContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.heroHeaderTextSTyle{
    font-family: "Nunito-Regular",sans-serif;
    color: var(--textBoldColor);
    font-size: 50px;
    font-weight: 400;
}
.heroHeaderBoldTextStyle{
    font-family: "Nunito-Bold",sans-serif;
    color: var(--textBoldColor);
    font-size: 50px;
    font-weight: 700;
}

/* hero section styles  end*/
/* about section styles start */
.aboutSectionContainerStyle{
    padding: 64px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.aboutInsideContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 64px;
    justify-content: space-between;
}
.aboutImageWrapperStyle{
    max-width: 419px;
    max-height: 392px;
    width: 100%;
    height: 100%;
}
.aboutTextContainerStyle{
    max-width: 765px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 48px;
}
.sectionHeaderTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: var(--textBoldColor);
}
.aboutHeaderTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 48px;
    color: var(--black);
}
.aboutDescTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--textLightColor);
}

/* about section styles end */
/* service section styles start */
.serviceSectionContainerStyle{
    padding: 68px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.serviceDropDownContainerStyle{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--dividerColor);
}
.serviceDropDownContainerStyle:last-child{border-bottom:none !important;}
.serviceDropItemHeaderTextStyle{
    max-width: 500px;
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: var(--text2BoldBlackColor);
}
.serviceDropItemDescContainerStyle{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.arrowImageWrapperStyle{
    width: 20px;
    height: 20px;
}
.serviceItemDescTextStyle{
    max-width: 708px;
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--textLightColor);
}
/* service section styles end */
/* black section styles start */
.blackSectionContainerStyle{
    padding: 64px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 64px;
    border-radius: 24px;
    background-color: var(--black);
    justify-content: space-between;
}
.blackSectionTextContainerStyle{
    max-width: 638px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 48px;
}
.blackSectionImageWrapperStyle{
    max-width: 506px;
    max-height: 405px;
    width: 100%;
    height: 100%; 
}
.blackSectionHeaderTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: var(--btnTextColor);
}
.blackSectionDescTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: var(--btnTextColor); 
}
/* black section styles end */
/* features section styles  start*/
.featuresSectionContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 68px 24px;
}
.featuresGridContainerStyle{
    display: grid;
    grid-template-columns: auto auto;
    gap: 64px;
}
.featuresItemContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--dividerColor);
}
.featuresItemContainerStyle:nth-child(5){border-bottom:none !important;}
.featuresItemContainerStyle:last-child{border-bottom:none !important;}
.featuresItemHeaderContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:16px;
}
.featuresImageWrapperStyle{
    width: 34px;
    height: 34px;
}
.featuresHeaderTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: var(--text2BoldBlackColor);
}
.featuresDescTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: var(--text2Color);
}
/* features section styles end */
@media screen and (max-width: 1655px) {}
@media screen and (max-width: 1388px) {
    .mainContainerStyle{
        max-width: 1132px;
        margin: auto;
    }
    /* mobile view Styles start  */
    .gamesContainerStyle{
        display: flex;
        flex-direction: row;
        gap: 24px;
        padding: 24px 0;
        width: 100%;
        height: 100%;
        overflow: scroll;
    }
    /* mobile view Styles end  */
     /* hero section styles start */
    .heroHeaderDescTextStyle{
        font-size: 20px;
    }
    .heroHeaderTextSTyle{
        font-size: 40px;
    }
    .heroHeaderBoldTextStyle{
        font-size: 40px;
    }
     /* hero section styles end */
     /* about section styles start */
     .aboutHeaderTextStyle{
        font-size: 32px;
    }
    .aboutDescTextStyle{
        font-size: 14px;
    }
    /* about section styles end */
       /* black section styles start */
    .blackSectionHeaderTextStyle{
     font-size: 24px;
    }
    .blackSectionDescTextStyle{
        font-size: 20px;
    }
    /* black section styles end */
}
@media screen and (max-width: 1206px) {
    .mainContainerStyle{
        max-width: 950px;
        margin: auto;
    }
}
@media screen and (max-width: 1040px) {
    .mainContainerStyle{
        max-width: 784px;
        margin: auto;
    }
    /* mobile view Styles start  */
    .mobileHeaderTextStyle{
        font-size: 40px;
    }
    .mobileDescTextStyle{
        font-size: 20px;
    }
    .mobileBtnStyle{display: none;
    }
    .scanBtnStyle{
        display: flex;
    }
    /* mobile view Styles end  */
    /* hero section styles start */

.heroMainContainerStyle{
    flex-direction: column;
    gap: 16px;
}
.heroSectionTextContainerStyle{
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 64px;
}
   /* hero section styles end */
   /* about section styles start */
   .aboutInsideContainerStyle{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 64px;
}
/* about section styles end */
/* service section styles start */
.serviceDropDownContainerStyle{
    align-items: center;
}
.serviceItemDescTextStyle{
    max-width: 100%;
}
/* service section styles end */
.blackSectionContainerStyle{
    flex-direction: column;
}
.featuresGridContainerStyle{
    display: grid;
    grid-template-columns: auto ;
    gap: 24px;
}
}
@media screen and (max-width: 550px) {
    /* mobile view Styles start  */
    .topNavSectionStyle{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 64px 24px 24px 24px;
    }
    /* mobile view Styles end  */
}
@media screen and (max-width: 428px) {
    .mainContainerStyle{
        padding: 48px 0 0 0;
    }
    /* hero section styles  start*/
   

    .imageMobileWrapperStyle{
        max-width: 380px;
        max-height: 311px;
        width: 100%;
        height: 100%; 
    }
    .heroHeaderTextSTyle{
        font-size: 32px;
    }
    .heroHeaderBoldTextStyle{
        font-size: 32px;
    }
    
    /* hero section styles end */
    /* about section styles start */
    .aboutSectionContainerStyle{
        padding: 64px 24px;
    }
    .sectionHeaderTextStyle{
        font-size: 20px;
        
    }
    .aboutInsideContainerStyle{
        gap: 24px;
    }
  
    .aboutMobileImageWrapperStyle{
        max-width: 387px;
        max-height: 360px;
        width: 100%;
        height: 100%;
    }
    .aboutTextContainerStyle{
        gap: 24px;
    }
    .aboutHeaderTextStyle{
        font-size: 20px;
    }
   
    /* about section styles end */
    /* service section styles start */
    .serviceSectionContainerStyle{
        padding: 64px 24px;
    }
    /* service section styles end */
    /* black section styles  start*/

    .blackSectionHeaderTextStyle{
        font-size: 20px;
    }
    .blackSectionDescTextStyle{
        font-size: 14px;
    }
    /* black section styles end */
    /* features section styles start */
    .featuresSectionContainerStyle{
        padding: 64px 24px;
    }
   
    .featuresItemContainerStyle:nth-child(5){border-bottom: 1px solid var(--dividerColor) !important;}
    .featuresImageWrapperStyle{
        width: 24px;
        height: 24px;
    }
    .featuresHeaderTextStyle{
        font-size: 16px;
    }
    .featuresDescTextStyle{
        font-size: 14px;
    }
    /* features section styles  end*/
}
