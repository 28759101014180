.imgStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.rewardContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--backgroundColor);
    border-radius: 24px;
    overflow: hidden;
}
.rewardImgWrapperStyle{
    max-width: 167px;
    width: 100%;
    height: 100%;
}
.rewardTextContainerStyle{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.lineWrapperStyle{
    max-width: 91px;
    max-height: 14px;
    width: 100%;
    height: 100%;
}
.rewardBoldTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: var(--black);
    text-align: center;
}
.rewardTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: var(--black);
}