.gameDetailsContainerStyle {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200,0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 24px;
}

.modalContainerStyle {
  width: 500px;
  height: auto;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
}

.closeBtnContainerStyle {
  display: flex;
  justify-content: flex-end;
  
}
.iconWrapperStyle{
  width: 24px;
  height: 24px;
}
.imgStyle{
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.modalBodyStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-size: 23px;
  text-align: center;
}
.modalTitleStyle{
  text-align: center;
}
 .modalFooterStyle {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.btnStyle{
  height: fit-content;
  padding: 8px 16px;
}