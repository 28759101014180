.imageStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.imageOuterContainerStyle{
    background-color: var(--white);
    width: 40px;
    height: 40px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imageWrapperStyle{
    width: 20px;
    height: 20px;
}
.buttonStyle{
    background-color: var(--btnBgColor);
    height: 56px;
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 8px 0 16px;
    gap: 16px;
    border: none;
    font-family: 'Nunito-SemiBold',sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: var(--btnTextColor);
    cursor: pointer;
}