

.gameContainerStyle{
    background-color: var(--black);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
padding-bottom: 24px;
}
.topContainerStyle{
    padding: 48px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.iconWrapperStyle{
    width: 20px;
    height: 20px;
}
.imgStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.scoreContainerStyle{
    padding: 8px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.levelTextStyle{
    
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: var(--white);
}
.totalLevelsTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: var(--levelTextColor);
}

.starsContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.starIconWrapperStyle{
    width: 24px;
    height: 24px;
}
.scorTextContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.scoreTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: var(--white); 
}
.triviaContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 100%;
}
.queOuterContainerStyle{
    padding: 8px 24px;
    margin: auto;
    width: 100%;
}
.questionContainerStyle{
background-color: var(--queBgColor);
border-radius: 16px;
padding: 16px 8px;
width: 100%;
}
.questionTextSTyle{
    max-width: 256px;
    margin: auto;
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--white);
    text-align: center;
}
.answersMainContainerStyle{
    padding: 8px 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}
.answerContainerStyle{
    width: 100%;
    padding: 8px 16px;
    border-radius: 32px;
    border: 1px solid var(--alphabetBtnColor);
    max-width: 100%;
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--alphabetBtnColor);
    text-align: center;
    outline: none;
    background-color: var(--black);
}
.correctAnsStyle{
    width: 100%;
    padding: 8px 16px;
    border-radius: 32px;
    background-color: var(--successColor);
    max-width: 100%;
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--alphabetBtnColor);
    text-align: center;
    outline: none;
    border: none;
}
.incorrectAnsStyle{
    width: 100%;
    padding: 8px 16px;
    border-radius: 32px;
    background-color: var(--errorColor);
    max-width: 100%;
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--alphabetBtnColor);
    text-align: center;
    outline: none;
    border: none;
}

.timerContainerStyle{
    display: flex;
align-items: center;
justify-content: center;
padding: 4px 8px;
background-color: var(--levelBtnBgColor);
border-radius: 32px;
width: fit-content;
margin-bottom: 24px;
}
.timerTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: var(--white);
}
.triviaAnswersContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}
.gameAnswersContainerStyle{
    background-color: var(--black);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    padding-bottom: 24px;
}
.btnStyles{
    background-color: var(--white);
    color: var(--black);
    padding: 8px 16px;
    height: fit-content;
}
.gameOverContainerStyle{
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

}
.congratsTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: var(--white);
}
.playAgainGameStyle{
    border: none;
    background-color: var(--white);
    color: var(--black);
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 12px;
    border-radius: 24px;
    width: fit-content;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
}
.popoverInsideContainerStyle{
    background-color: var(--white);
    width: 100%;
    padding: 24px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.gameTextSTyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: var(--black);
    text-align: center;
}
.btnContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.btnStyle{
    background-color: var(--black);
    color: var(--white);
    height: 27px;
    padding: 0 16px;
    font-size: 14px;
    width: fit-content;
}
.closeIconContainerSTyle{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.popoverHeaderContainerStyle{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}
.popOverHeaderTextStyle{
    font-family: "Nunito-Bold",sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: var(--gameScoreTextColor);
}
.popOverDescTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--gameScoreTextColor);
    text-align: center;
}
.inputContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.skipTextSTyle{
    font-family: "Nunito-Bold",sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--skipTextColor);
    text-align: center;
}
.skipBlackTextSTyle{
    font-family: "Nunito-Bold",sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--black);
    text-align: center;
}
.btnNextStyle{
    padding: 0 16px;
}
.displayTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: var(--black);
}
.playerContainerStyle{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
.playerImgWrapperStyle{
    max-width: 105px;
    max-height: 108px;
    width: 100%;
    height: 100%;
    }
    .playerNameContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    }