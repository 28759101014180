.topViewContainerSTyle{
padding: 78px 24px;
display: flex;
flex-direction: column;
gap: 48px;
align-items: center;
}
.topViewTextContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
}
.nameContainerStyle{
    width: 100%;
    display: flex;
    align-items: flex-start;
}
.nameTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 32px;
    color: var(--textBoldColor); 
}
.homeHeroImgWrapperStyle{
    max-width: 178px;
    max-height: 173px;
    width: 100%;
    height: 100%;
}
.imgStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.topViewBottomTextContainerStyle{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 352px;
    gap: 24px;
}
.topViewTextInsideWrapperStyle{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.lineImageWrapperStyle{
    max-width: 223px;
    max-height: 32px;
    width: 100%;
    height: 100%;
}
.headerBoldTextStyle{
    font-family: "Nunito-Bold",sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: var(--textBoldColor);
    text-align: center;
}
.headerTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 32px;
    color: var(--textBoldColor);
}
.topViewDescTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--textLightColor);
}

.middleViewMainContainerStyles{
    padding: 64px 24px;
    display: flex;
    flex-direction: column;
    gap: 34px;
    background-color: var(--black);
}
.middleTopContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.middleHeaderTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: var(--white);
}
.middleDescContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.middleDescTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--white);
}
.middleArrowContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.chevronIconWrapperStyle{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

