.leaderBoardContainerStyle{
    padding: 64px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}


.playGameContainerStyle{
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    padding: 16px 0;
}
.gameImgWrapperStyle{
    max-width: 93px;
    max-height: 94px;
    width: 100%;
    height: 100%;
    
}
.imgStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
}
.gameTextContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.gameNameTextStyle{
    font-family: "Nunito-Bold",sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: var(--black);
}
.gameDescTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--gameDescLightColor);
}

.playerContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    
}
.playerImageContainerStyle{
    display: flex;
    flex-direction: row;
    position: relative;

}
.playerImgWrapperStyle{
max-width: 105px;
max-height: 108px;
width: 100%;
height: 100%;
}
.lineWrapperStyle1{
    position: absolute;
    bottom:0;
    right: 90px;
    max-width: 100px;
    max-height: 32px;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.lineWrapperStyle2{
    position: absolute;
    top: 0;
    left: 90px;
    max-width: 100px;
    max-height: 32px;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.playerNameTextStyle{
    font-family: "Nunito-Bold",sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: var(--black);
}
.gameScoreContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.scoreTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--gameScoreTextColor);
}
.scoreNoTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: var(--gameScoreTextColor); 
}
.coinsWrapperStyle{
    width: 24px;
    height: 24px;
}
.dividerStyle{
    width: 100%;
    height: 1px;
    background-color: var(--dividerColor);
}
.scroeBoardContainerStyle{
    display: flex;
    flex-direction: column;
gap: 24px;
}
.headerContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.headerTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: var(--black); 
}
.descTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--textLightColor);
}
.leaderTableContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.tabContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.selectedTabStyle{
    background-color: var(--black);
    padding: 4px 16px;
    border-radius: 24px;
}
.tabSelectedTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--white); 
}
.tabTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--textLightColor);
}
.tableContainerStyle{
    border-collapse: collapse;
    width: 100%;
    border: none;
}
.tableHeaderCellStyle{
    text-align: center;
    padding: 8px;
    border: none;
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: var(--textLightColor);
}
.rowContainerStyle{
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;

}
.rowSelectedContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    background-color: var(--backgroundColor);
}
.tableRankCellStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
    border: none;
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: var(--black);  
}
.rankContainerStyle{
    width: 34px;
    height: 34px;
    background-color: var(--dividerColor);
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tableCellStyle {
    text-align: center;
    padding: 8px;
    border: none;
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: var(--black); 
  }
  .tableScoreCellStyle{
    text-align: center;
    padding: 8px;
    border: none;
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: var(--black);
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: center;
  }
  .winnerWrapperStyle{
    width: 34px;
    height: 34px;
  }