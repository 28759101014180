.gamesMainContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 64px 24px;
}
.gameInsideContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 34px;
}
.playContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.playGameContainerStyle{
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    padding: 16px 0;
}
.gameImgWrapperStyle{
    max-width: 93px;
    max-height: 94px;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    overflow: hidden;
    border-radius: 16px;
}
.imgStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
}
.gameTextContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.gameNameTextStyle{
    font-family: "Nunito-Bold",sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: var(--black);
}
.gameDescTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--gameDescLightColor);
}
.gameRatingsContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.itemContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    max-width: 127px;
    width: 100%;
}
.itemScoreContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.itemHeaderTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: var(--gameScoreTextColor);
}
.itemSecondTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: var(--gameScoreTextColor);

}
.iconWrapperStyle{
    width: 24px;
    height: 24px;
}
.dividerStyle{
    width: 1px;
    height: 32px;
    border-radius: 1px;
    background-color: var(--dividerColor);
}
.buttonStyle{
    padding: 0 16px;
    font-size: 20px;
}
.gameDescContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.gameAboutItemStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.aboutHeaderTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: var(--black);
}
.downArrWrapperStyle{
    width: 20px;
    height: 20px;
}
.gameAboutDescTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--gameScoreTextColor);
}
.gameImgMainContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.gameImgTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: var(--black);
}
.imagesContainerStyle{
    display: flex;
    flex-direction: row;
    gap: 16px;
    overflow: scroll;
}
.gameImageWrapperStyle{
min-width: 171px;
min-height: 115px;
width: 100%;
height: 100%;

}
.rewardsContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.rewardsTextContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 8px;
}