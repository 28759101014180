.gameContainerStyle{
    background-color: var(--black);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
}
.topContainerStyle{
    padding: 48px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.iconWrapperStyle{
    width: 20px;
    height: 20px;
}
.imgStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.scoreContainerStyle{
    padding: 8px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.levelTextStyle{
    
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: var(--white);
}
.totalLevelsTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: var(--levelTextColor);
}

.starsContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.starIconWrapperStyle{
    width: 24px;
    height: 24px;
}
.scorTextContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.scoreTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: var(--white); 
}
.gameViewContainerStyle{
    padding: 24px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
}
.hangManImgWrapperStyle{
    max-width: 200px;
    max-height: 100px;
    width: 100%;
    height: 100%;
}
.gameLevelNameContainerStyle{
display: flex;
align-items: center;
justify-content: center;
padding: 4px 8px;
background-color: var(--levelBtnBgColor);
border-radius: 32px;
}
.gameLevelNameStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: var(--white); 
}
.wordContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 8px;
}
.lettersTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: var(--white); 
    width: 20px;
    height: 20px;
    text-align: center;
}
.alphabetBtnsContainerStyle{
    max-width: 280px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 8px 24px;
    
}
.btnStyles{
    border: none;
    background: none;
    color: var(--alphabetBtnColor);
    font-size: 16px;
    font-weight: 600;
    font-family: "Nunito-SemiBold",sans-serif;
    background-color:var(--black);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.timerContainerStyle{
    margin: auto;
    display: flex;
align-items: center;
justify-content: center;
padding: 4px 8px;
background-color: var(--levelBtnBgColor);
border-radius: 32px;
width: fit-content;
}
.timerTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: var(--white);
}
.gameOverContainerStyle{
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

}
.congratsTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: var(--white);
}
.playAgainGameStyle{
    border: none;
    background-color: var(--white);
    color: var(--black);
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 12px;
    border-radius: 24px;
    width: fit-content;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
}
.popoverInsideContainerStyle{
    background-color: var(--white);
    width: 100%;
    padding: 24px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.gameTextSTyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: var(--black);
    text-align: center;
}
.btnContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.btnStyle{
    background-color: var(--black);
    color: var(--white);
    height: 27px;
    padding: 0 16px;
    font-size: 14px;
    width: fit-content;
}
.closeIconContainerSTyle{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.popoverHeaderContainerStyle{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}
.popOverHeaderTextStyle{
    font-family: "Nunito-Bold",sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: var(--gameScoreTextColor);
}
.popOverDescTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--gameScoreTextColor);
    text-align: center;
}
.inputContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.skipTextSTyle{
    font-family: "Nunito-Bold",sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--skipTextColor);
    text-align: center;
}
.skipBlackTextSTyle{
    font-family: "Nunito-Bold",sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--black);
    text-align: center;
}
.btnNextStyle{
    padding: 0 16px;
}
.displayTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: var(--black);
}
.playerContainerStyle{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
.playerImgWrapperStyle{
    max-width: 105px;
    max-height: 108px;
    width: 100%;
    height: 100%;
    }
    .playerNameContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    }