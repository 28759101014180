.mainContainerStyle{
    padding: 64px 24px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;
}
.topViewContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.headerTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 32px;
    color: var(--textBoldColor);
}
.headerBoldTextStyle{
    font-family: "Nunito-Bold",sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: var(--textBoldColor);
}
.headerDescTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--textLightColor);
}
.cardViewStyle{
    max-width: 380px;
    max-height: 177px;
    width: 100%;
    height: 100%;
    border-radius: 200px;
    background: linear-gradient(32deg, rgba(25, 24, 26, 0.02) 0%, rgba(65, 64, 67, 0.07) 100%);
    padding: 48px 22px;
}
.cardTextStyle{
    text-align: center;
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: var(--gameScoreTextColor);
}
.cardBoldTextStyle{
    font-family: "Nunito-Bold",sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: var(--gameScoreTextColor);
}