.navMainContainerStyle{
padding: 48px 24px 0 24px;
position: relative;
}

.navBarInsideContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.logoTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: var(--textBoldColor);
}
.logoWhiteTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: var(--white);
}
.menuImageWrapperStyle{
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.imgStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.navMainOpenContainerStyle{
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
    padding: 48px 24px 0 24px;
    background-color: var(--black); 
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 100px;
    transition: 0.8s ease-in-out;
    transform: translateY(0%);
    z-index: 2;
}
.navMainCloseContainerStyle{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 48px 24px 0 24px;
    background-color: var(--black); 
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 100px;
    transition:  0.8s ease-in-out;
    transform: translateY(-200%);
    z-index: 1;
}
.menuContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.menuTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: var(--menuOptionColor);
}
.menuItemWrapperStyle{
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: baseline;
}
.menuSelectedTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: var(--white);
}
.leftDividerStyle{
    background-color: var(--white);
    border-radius: 5px;
    width: 5px;
    height: 100%;
}
.leaderBoardStyle{
    display: flex;
    flex-direction: column;
    gap: 24px;
}