.imgStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.gameCardContainerStyle{
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px;
    border-radius: 24px;
    background-color: var(--gameCardBgColor);
    align-items: center;
}
.gameImgWrapperStyle{
    max-width: 130px;
    max-height: 130px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 16px;
}
.gameTextContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.gameTextInsideContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.gameNameTextStyle{
    font-family: "Nunito-Bold",sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--btnTextColor);
}
.gameDescTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--btnTextColor);
}
.buttonStyle{
    height: 27px;
    padding: 0 16px;
    color: var(--textBoldColor);
    background-color: var(--white);
    font-size: 14px;
    width: fit-content;
}

