.progressBarSTyle{
    width: 140px;
    height: 5px;
    border-radius: 5px;
    background-color: var(--gameCardBgColor);
    }
    .progressInsideBarSTyle{
    width: 33%;
    height: 100%;
    border-radius: 5px;
    background-color: var(--white);
    }