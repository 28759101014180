
.cellInputStyle{
  background-color:var(--sudokuInputBgColor);
  color: var(--white);
  width: 30px;
  height: 30px;
  text-align: center;
border-radius: 15px;
padding-top: 3px;
padding-left: 1px;
}
.nonModifiableCellStyle{
  background-color:var(--sudokuCellInputCOlor);
color: var(--black);
width: 30px;
height: 30px;
text-align: center;
border-radius: 15px;
padding-top: 3px;
padding-left: 1px;
}
.borderStyle{
  border-right: 2px solid var(--alphabetBtnColor);
}
.bottomBorderStyle{
  border-bottom: 2px solid var(--alphabetBtnColor); 
}

