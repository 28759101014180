.Grid {
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.gridTableStyle{
  border-collapse: collapse;
}
.bodyStyle{
  display: flex;
  flex-direction: column;
}
.rowStyle {
  /* border: 1px solid #141c3a; */
  display: flex;
  flex-direction: row;
}

table {
}
