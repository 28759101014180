.imageStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.imageWrapperStyle{
    width: 24px;
    height: 24px;
}
.footerContainerStyles{
    width: 100%;
    background-color: var(--black);
    height: 106px;
    display: flex;
    gap: 24px;
    align-items: flex-end;
    justify-content:center;
    padding-bottom: 34px;
}