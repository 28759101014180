body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Nunito-Bold";
  src: url("../src/assets/fonts//Nunito-VariableFont_wght.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Nunito-SemiBold";
  src: url("../src/assets/fonts//Nunito-VariableFont_wght.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Nunito-Medium";
  src: url("../src/assets/fonts//Nunito-VariableFont_wght.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Nunito-Regular";
  src: url("../src/assets/fonts//Nunito-VariableFont_wght.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Nunito-Light";
  src: url("../src/assets/fonts//Nunito-VariableFont_wght.ttf");
  font-weight: 300;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}
:root {
  --black: #000000;
  --white: #ffffff;
--btnBgColor:#1C1C1C;
--btnTextColor:#FCFCFC;
--textBoldColor:#232222;
--textLightColor: #646262;
--text2BoldBlackColor:#2F2736;
--dividerColor:#D9D9D9;
--text2Color:#929292;
--menuOptionColor:#EBEBEB;
--backgroundColor:#F5F5F5;
--gameCardBgColor:#303030;
--lightTextcolor: rgba(47, 39, 54, 0.58);
--textlightBlackColor:#585858;
--gameCardLightColor:#1F1F1F;
--gameCardLightBgColor:#FAF9F9;
--gameDescLightColor:#555452;
--gameScoreTextColor: #3D3B3A;
--levelTextColor:#BDBDBD;
--levelBtnBgColor:rgba(255, 255, 255, 0.17);
--alphabetBtnColor: rgba(252, 252, 252, 0.66);
--sudokuInputBgColor:#323232;
--sudokuCellInputCOlor:rgba(255, 255, 255, 0.80);
--queBgColor:rgba(78, 78, 78, 0.17);
--successColor:#20B013;
--errorColor:#D93E2E;
--inputBorderColor:rgba(0, 0, 0, 0.23);
--skipTextColor:rgba(85, 83, 87, 0.36);
}
