.gameContainerStyle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    gap: 10px;
    position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
  }
  .sudokuBtnContainerStyle{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    gap: 24px;
    padding: 24px;
  }
  .btnStyle{
    color: var(--black);
    background-color: var(--white);
    padding: 8px 16px;
    height: fit-content;
  }
  .topContainerStyle{
    padding: 48px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.iconWrapperStyle{
    width: 20px;
    height: 20px;
}
.imgStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.timerContainerStyle{
  display: flex;
align-items: center;
justify-content: center;
padding: 4px 8px;
background-color: var(--levelBtnBgColor);
border-radius: 32px;
width: fit-content;
}
.timerTextStyle{
  font-family: "Nunito-Regular",sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--white);
}
.popoverInsideContainerStyle{
  background-color: var(--white);
  width: 100%;
  padding: 24px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.gameTextSTyle{
  font-family: "Nunito-Regular",sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: var(--black);
  text-align: center;
}
.btnContainerStyle{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.btnStyle{
  background-color: var(--black);
  color: var(--white);
  height: 27px;
  padding: 0 16px;
  font-size: 14px;
  width: fit-content;
}
.closeIconContainerSTyle{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.popoverHeaderContainerStyle{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.popOverHeaderTextStyle{
  font-family: "Nunito-Bold",sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: var(--gameScoreTextColor);
}
.popOverDescTextStyle{
  font-family: "Nunito-Regular",sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--gameScoreTextColor);
  text-align: center;
}
.inputContainerStyle{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.skipTextSTyle{
  font-family: "Nunito-Bold",sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--skipTextColor);
  text-align: center;
}
.skipBlackTextSTyle{
  font-family: "Nunito-Bold",sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--black);
  text-align: center;
}
.btnNextStyle{
  padding: 0 16px;
}
.displayTextStyle{
  font-family: "Nunito-Regular",sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: var(--black);
}
.playerContainerStyle{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.playerImgWrapperStyle{
  max-width: 105px;
  max-height: 108px;
  width: 100%;
  height: 100%;
  }
  .playerNameContainerStyle{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  }

