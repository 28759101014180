.inputContainerStyle{
    display: flex;
    flex-direction: column;
    gap:16px;
    width: 100%;
}
.labelTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--black);
}
.inputStyle{
    border-bottom: 1px solid var(--inputBorderColor);
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--black);
    padding: 0 0 8px 0;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
}