.voucherContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--backgroundColor);
    border-radius: 24px;
   
}
.imgStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.topVoucherImgWrapperStyle{
    max-width: 165px;
    max-height: 200px;
    width: 100%;
    height: 100%;
}
.voucherBoldTextStyle{
    font-family: "Nunito-Bold",sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: var(--black);
    padding: 16px;
}
.voucherTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: var(--black);
}