.middleViewMainContainerStyles{
    
    display: flex;
    flex-direction: column;
    gap: 34px;
}
.middleTopContainerStyle{
    padding: 64px 24px 0 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.middleHeaderTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: var(--black);
}
.middleDescContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.middleDescTextStyle{
    font-family: "Nunito-Regular",sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--textlightBlackColor);
}
.middleArrowContainerStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.buttonArrContainerStyle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 24px;
    border: 1px solid var(--black);
}
.chevronIconWrapperStyle{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.gamesCardMainContainerStyle{
    display: flex;
    flex-direction: row;
    gap: 24px;
    overflow: scroll;
    padding: 0 24px 0 24px;
}
.gameCardOuterContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.gameCardContainerStyle{
    max-width: 380px;
    width:  380px;
}
.progressBarSTyle{
    margin: 0 24px;
background-color: var(--dividerColor);
}
.progressInsideBarSTyle{
background-color: var(--gameCardBgColor);
}
.gameMostPlayedCardContainerStyle{
    max-width: 380px;
    width:  380px;
    background-color: var(--gameCardLightBgColor);

}
.nameTextStyle{
color: var(--black);
white-space: nowrap;
}
.descTextStyle{
    color: var(--black);  
}
.btnStyle{
    background-color: var(--black);
    color: var(--white);
}
.imgWrapperStyle{
    max-width: 348px;
    max-height: 203px;
    border-radius: 24px;
    overflow: hidden;
}
.gameMoreGamesContainerStyle{
    flex-direction: column;
    gap: 16px;
    max-width: 380px;
    width:  380px;
    background-color: var(--gameCardLightBgColor);
}
.gameNameContainerStyle{
    flex-direction: row;
    gap: 32px;
    align-items: center;
}
.moreCardBtnStyle{
    background-color: var(--black);
    color: var(--white);
    font-size: 16px;
    height: 54px;
    width: 100%;
}
