.numberBoardStyle{
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nubersFirstContainerStyle{
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: auto;
}
.numberBtnStyle{
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: var(--black);
  border: 1px solid var(--levelTextColor);
}

.numberBtnSelectedStyle{
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: var(--alphabetBtnColor);
  border: 1px solid var(--levelTextColor);
}
.textColorStyle{
  color: var(--white);
}
.textSelectedColorStyle{
  color: var(--black);
}


