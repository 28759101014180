.footerMainContainerStyle{
    padding: 64px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.footerHeaderTextStyle{
    font-family: "Nunito-SemiBold",sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: var(--text2BoldBlackColor);
}
.dividerStyle{
    width: 100%;
    height: 1px;
    background-color: var(--dividerColor);
}
.socialIconsContainerStyle{
    display: flex;
    flex-direction: row;
    gap: 24px;
}
.iconOuterContainerStyle{
    width: 44px;
    height: 44px;
    background-color: var(--text2BoldBlackColor);
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.socialIconWrapperStyle{
    width: 24px;
    height: 24px;
}
.imgStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.contactMainContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.contactTextStyle{
    font-family: "Nunito-Bold",sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: var(--text2BoldBlackColor);
}
.contactBottomContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.contactItemContainerStyle{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.contactIconWrapperStyle{
    width: 20px;
    height: 20px;
}
.mailTextStyle{
    font-family: "Nunito-Medium",sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--lightTextcolor);
}
.privacyMainContainerStyle{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.privacyTextStyle{
    font-family: "Nunito-Medium",sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--text2BoldBlackColor);
    text-decoration: underline;
}
.copyRightsTextStyle{
    font-family: "Nunito-Medium",sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--lightTextcolor);
}