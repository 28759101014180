.difficultySelectionModalStyle {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0.3);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  z-index: 1000;
}

.modalContainerStyle {
  width: 500px;
  height: auto;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin: 10px;
}
.iconWrapperStyle{
  width: 24px;
  height: 24px;
}
.imgStyle{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modalTitleStyle{
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.modalCloseBtnContainerStyle {
  display: flex;
  justify-content: flex-end;
}



 .difficultyModalBodyStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-size: 23px;
  text-align: center;
  margin: 30px 0;

  visibility: hidden;
}

 .modalFooterStyle {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

.difficultySelectionContainerStyle {
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.difficultyEasyStyle {
  max-width: 100px;
  width: 100%;
  border-style: solid;
  border-width: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  border-color: lightgreen;
  border-radius: 30px;
  padding: 10px;
}

.difficultyMediumStyle {
  max-width: 100px;
  width: 100%;
  border-style: solid;
  border-width: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  border-color: orange;
  border-radius: 30px;
  padding: 10px;
}
.difficultyHardStyle {
  max-width: 100px;
  width: 100%;
  border-style: solid;
  border-width: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  border-color: red;
  border-radius: 30px;
  padding: 10px;
}
.textStyle{
  font-size: 16px;
  color: var(--black);
}

.animationStyle {
  height: 50%;
}


.btnStyle{
  padding: 8px 16px;
  height: fit-content;

}
